import React, {useRef} from 'react';
import "./customer-details.scss"
import { Container, Form } from 'react-bootstrap';
import jsPDF from 'jspdf';

export default function CustomerDetails() {
    const reportTemplateRef = useRef(null);
    const handleGeneratePdf = () => {
		const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});

		// Adding the fonts.
		doc.setFont('Inter-Regular', 'normal');

		doc.html(reportTemplateRef.current, {
			async callback(doc) {
				await doc.save('sanple');
			},
		});
	};
    return (
        <React.Fragment>
            <div className='page-section' ref={reportTemplateRef}>
                <Container className='container-sm-p0'>
                    <div className='customer-details-page' id="pdf-content">
                        <div className='customer-header'>
                            <div>
                                <h5>SO065748</h5>
                                <h6>Feb 6 2024 2:44 PM</h6>
                            </div>
                            <div>
                                <button type='button' className='btn btn-primary' onClick={handleGeneratePdf}><i className="fa-solid fa-file-pdf"></i> Download</button>
                            </div>
                        </div>
                        <div className='customer-details' id="content">
                            {/* CUSTOMER NAME */}
                            <div className='customer-name  mb-3'>
                                <h3>Customer Name</h3>
                                <h4>Unknow</h4>
                            </div>

                            {/* CUSTOMER TABLE */}
                            <div className='customer-table mb-3'>
                                <table className='w-100'>
                                    <thead>
                                        <tr>
                                            <th>Table #</th>
                                            <th>Order Status</th>
                                            <th>Payment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>D14</td>
                                            <td>
                                                <span className='text-uppercase text-danger'>Ongoing</span>
                                            </td>
                                            <td>
                                            <span className='text-uppercase text-danger'>Pending</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* ORDER SUMMARY */}
                            <div className='order-summary mb-3'> 
                                    <table className='w-100'>
                                        <thead>
                                            <tr>
                                                <th>Order Summary</th>
                                                <th>Rate</th>
                                                <th>Qty.</th>
                                                <th>Amt.</th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            <tr>
                                                <td>Arabian Dastar Sp. Chicken Soup</td>
                                                <td>200</td>
                                                <td>2</td>
                                                <td><b>400</b></td>
                                            </tr>

                                            <tr>
                                                <td>Arabian Dastar Sp. Chicken Soup</td>
                                                <td>200</td>
                                                <td>2</td>
                                                <td><b>400</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>

                            {/* PAYMENT SUMMARY */}
                            <div className='payment-summary mb-3'>
                                <h2>Payment Summary</h2>

                                <ul>
                                    <li>
                                        <span>Sub Total (2 Items)</span>
                                        <b>₹ 950</b>
                                    </li>

                                    <li>
                                        <span>GST (5%)</span>
                                        <b>₹ 250</b>
                                    </li>

                                    <li>
                                        <span>Customer Tips</span>
                                        <b>₹ 50</b>
                                    </li>

                                    <li className='last'>
                                        <span>Total Payable</span>
                                        <b>₹ 1150</b>
                                    </li>
                                </ul>
                            </div>

                            {/* ADD TIPS */}
                            <div className='add-tips'>
                                <h2 className='mb-3'>Want to add a tip?</h2>
                                <div>
                                    <Form.Control name="tips" placeholder="Tips" />
                                </div>

                                <div className='tips-bttn mt-3'>
                                    <button type="button" className="btn btn-secondary">₹ 50</button>
                                    <button type="button" className="btn btn-secondary">₹ 100</button>
                                    <button type="button" className="btn btn-secondary">₹ 150</button>
                                </div>
                            </div>

                            <div className='pay-now-bttn'>
                                <button type='button' className='btn btn-primary'>
                                    <div>
                                        <span>You Pay:</span>
                                        <h6>₹ 110</h6>
                                    </div>
                                    <div>
                                        <h4 className='text-uppercase'>Payment Now</h4>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}