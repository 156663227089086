import React, { useState, useEffect } from 'react';
import './web-view.scss';
import { Row, Col, Nav, Tab, Dropdown, Form } from 'react-bootstrap';
import mainCatlist from '../../../Json/main-category.json';
import categorylist from '../../../Json/category.json';
import itemslist from '../../../Json/items.json';
import EmptyItemsState from '../_empty-state/items-empty';


export default function DetailsWebView() {
    const [categories, setCategories] = useState(categorylist);
    const [items, setItems] = useState(itemslist)

    const [mainCatName, setMainCatName] = useState(mainCatlist[0].mainCatName);
    const [categoryName, setCatagoryName] = useState(categorylist[0]?.catalogueName);

    const [subCategory, setSubCategory] = useState(categorylist[0]?.id);
    useEffect(() => {
        setCategories(categorylist?.filter((category) => category?.mainCategoryId === mainCatlist[0].id));
        setItems(itemslist?.filter((item) => item?.catId === categories[0]?.id));
        setSubCategory(categories?.filter((category) => category?.mainCategoryId === mainCatlist[0].id)[0].id)
        setMainCatName(mainCatlist[0]?.mainCatName);
        setCatagoryName(categories[0]?.name);
    }, []);
    const handleMainCatClick = (option) => {
        setMainCatName(option?.mainCatName);
        setCategories(categorylist?.filter((category) => category?.mainCategoryId === option?.id));
        setCatagoryName(categorylist?.filter((category) => category?.mainCategoryId === option?.id)[0]?.name);
        setSubCategory(categorylist?.filter((category) => category?.mainCategoryId === option?.id)[0]?.id);
        setItems(itemslist?.filter((item) => item?.catId === categories[0]?.id))
    };
    const handleCategoryClick = (tab) => {
        setCatagoryName(tab?.name);
        setItems(itemslist?.filter((item) => item?.catId === tab?.id))
    }
    const handleSubCategoryTab = (tab) => {
        setSubCategory(tab.id);
    };


    return (

        <React.Fragment>
            <div className='categories-wrapper'>
                <Tab.Container id="left-tabs-example" activeKey={subCategory}>
                    <Row>
                        <Col md={3} xs={12} className='pe-0'>
                            <div className='side-nav-tabs'>
                                <div className='web-view'>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">{mainCatName}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {mainCatlist?.map((item, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleMainCatClick(item)}>{item.mainCatName}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className='items-tabs-header'>
                                    {categories?.map((tab) => (
                                        <Nav
                                            variant="pills"
                                            className="flex-column"
                                            key={tab.id}
                                            activeKey={tab.id}
                                            onSelect={() => handleSubCategoryTab(tab)}
                                            onClick={() => handleCategoryClick(tab)}>
                                            <Nav.Item>
                                                <Nav.Link eventKey={tab.id}>{tab.name}</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col md={9} xs={12} className='ps-0 sm-pr-0'>
                            <div className='category-tabs-contents'>
                                <div className='category-title'>
                                    <h4>{categoryName}</h4>
                                    <div className='mobile-view'>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">{mainCatName}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {mainCatlist?.map((item, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleMainCatClick(item)}>{item.mainCatName}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                    <div className='choose-filter-wrapper d-flex'>
                                        <div className='choose-filter'>
                                            <input type="radio" id="all" value="All" name="chooseFilter" />
                                            <label htmlFor="all">All</label>
                                        </div>
                                        <div className='choose-filter'>
                                            <input type="radio" id="non-veg" value="NonVeg" name="chooseFilter" />
                                            <label htmlFor="non-veg"><span className='dish-indicator non-veg'></span> <span className='align-middle'>Non-veg</span></label>
                                        </div>
                                        <div className='choose-filter'>
                                            <input type="radio" id="veg" value="Veg" name="chooseFilter" />
                                            <label htmlFor="veg"><span className='dish-indicator veg'></span> <span className='align-middle'>Veg</span></label>
                                        </div>
                                    </div>
                                </div>
                                <Tab.Content>
                                    {items?.length !== 0 ? (
                                        <>
                                            {items && items?.length !== 0 && items?.map((item) => (
                                                <Tab.Pane className={`tabs-content-wrapper ${item.id ? "active show" : ''}`} key={item.id}>
                                                    <div className='p-3'>
                                                        <div className='item-dish-list'>
                                                            <Row className='align-items-center'>
                                                                <Col xl={8} xs={8}>
                                                                    <div className='item-dish-left'>
                                                                        <div>
                                                                            <div className='dish-img'>
                                                                                <img src={`https://freshlo.oss-ap-south-1.aliyuncs.com/ad_uat/category-images/MNCTG097.png`} alt="varient" className="w-100 h-100" />
                                                                            </div>
                                                                        </div>
                                                                        <div className='content'>
                                                                            <div className='dish-time-status'>
                                                                                <span className='dish-indicator non-veg'></span>
                                                                                <span className='time'><i className="fa-regular fa-clock"></i> 5-10 mins</span>
                                                                                <span className="pepper-hot">
                                                                                    <i className="fas fa-pepper-hot" style={{ color: '#cacaca' }}></i>
                                                                                    <i className="fas fa-pepper-hot" style={{ color: '#cacaca' }}></i>
                                                                                    <i className="fas fa-pepper-hot"></i>
                                                                                </span>
                                                                            </div>
                                                                            <h3 className='two-line-ellipsis'>{item.catalogueName}</h3>
                                                                            {/* <p className='one-line-ellipsis'>{item.description}</p> */}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xl={4} xs={4}>
                                                                    <div className='item-dish-right'>
                                                                        <Form.Select>
                                                                            <option value="full">FULL - ₹ 1250</option>
                                                                            <option value="half">HALF - ₹ 650</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <EmptyItemsState />
                                        </>
                                    )}
                                </Tab.Content>

                            </div>

                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </React.Fragment>
    )
}