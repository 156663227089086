import React from 'react';
import './details.scss';
import { Link } from 'react-router-dom';
import { Container, Form } from 'react-bootstrap';
import DetailsWebView from './web-view';
//import DetailsMobileView from './mobile-view';

export default function MenuDetails() {
    return (
        <React.Fragment>
            <section className='page-section'>
                <Container>
                    <div className='sub-header'>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><span><i className="fa-solid fa-chevron-right"></i></span></li>
                            <li><span>Detils</span></li>
                        </ul>

                        <div className="category-search-input">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <Form.Control type="text" placeholder="Search" name="searchValue" id="searchValue" className="form-control" autoComplete='off' />
                        </div>
                    </div>
                    <div className='page-wrapper'>
                    <DetailsWebView />
                        {/* <div className='web-view'>
                            <DetailsWebView />
                        </div>
                        <div className='mobile-view'>
                            <DetailsMobileView />
                        </div> */}
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}