import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './view/pages/common/header';
import Menu from './view/pages/menu/menu';
import MenuDetails from './view/pages/menu/details';
import CustomerDetails from './view/pages/customer/customer-details';
import Scanner from './view/pages/scanner/scanner';
import './scss/style.scss'
import './App.scss';


function App() {
  return (
    <React.Fragment>
      <Header />
      <div className='main'>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="/scanner" element={<Scanner />} />
            <Route path="/menu/details" element={<MenuDetails />} />
            <Route path="/customer/details" element={<CustomerDetails />} />
          </Routes>
        </BrowserRouter>
      </div>
    </React.Fragment>
  );
}

export default App;
