import React, { useState } from 'react'
import QrReader from 'react-qr-scanner';
import { Container } from 'react-bootstrap';

export default function Scanner() {
    const [result, setResult] = useState('');

    const handleScan = (data) => {
        if (data) {
            setResult(data);
        }
    };

    const handleError = (error) => {
        console.error(error);
    };
    return (
        <React.Fragment>
            <div className='page-section'>
                <Container>
                   <div className='p-3'>
                   <h1>QR Code Scanner</h1>
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                    />
                    <p>Scanned Result: {result}</p>
                   </div>
                </Container>
            </div>
        </React.Fragment>
    )
}