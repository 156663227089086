import React from 'react';
import './menu.scss';
import { Card, Container, Row, Col, Image } from 'react-bootstrap';
import menulist from "../../../Json/menu.json";
import { Link } from 'react-router-dom';

export default function Menu() {
    return (
        <React.Fragment>
            <section className='menu-section'>
                <Container>
                    <Card className='card-one'>
                        <Card.Header>
                            <h3 className='page-header-title'>Our Menu</h3>
                            <p className='page-header-sub-title'>Dive into a selection that caters to every plate and craving.</p>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                {menulist?.map((item, index) => (
                                    <Col xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                                        <Link to="/menu/details" className='menu-item' href='/menu/details'>
                                            <div className='menu-item-img'>
                                                <Image src={`https://freshlo.oss-ap-south-1.aliyuncs.com/ad_uat/category-images/${item.mainCategoryId}.png`} />
                                            </div>
                                            <h3 className='menu-item-name one-line-ellipsis'>{item.name}</h3>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </section>
        </React.Fragment>
    )
}