import React from 'react';
import "./items-empty.scss";
import noOrderEmpty from "../../../assets/img/no-results.png"


export default function EmptyItemsState() {
    return (
        <React.Fragment>
            <div className='empty-state'>
                <div>
                    <img src={noOrderEmpty} alt="No Order" />
                </div>
                <h3 className="empty-state-title">You have no Items.</h3>
            </div>
        </React.Fragment>
    )
}