import React from 'react';
import "./header.scss";
import { Navbar, Container, Nav, Image, Form } from "react-bootstrap";
import logo from "../../../assets/img/main-logo.png";


export default function Header() {
    return (
        <React.Fragment>
            <Navbar fixed="top" expand="lg" className="page-header">
                <Container className='custom-container'>
                    <Navbar.Brand href="#"><Image src={logo} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse className='justify-content-end bg-white header-menu' id="navbarScroll">
                        <Nav className="d-flex">
                            <Nav.Link href="#">Home</Nav.Link>
                            <Nav.Link href="#">About us</Nav.Link>
                            <Nav.Link href="#">Reserve Table</Nav.Link>
                            <Nav.Link href="#">Contact</Nav.Link>
                            <Nav.Link href="#"><i className="ri-user-line"></i></Nav.Link>
                            <Form.Select className='w-auto'>
                                <option value="1">हि-Hindi</option>
                                <option value="2">ع-Arabic</option>
                                <option value="3">Urdu-Urdu</option>
                                <option value="3">En - English</option>
                            </Form.Select>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    )
}